import { Container, Grid, GridItem, Heading } from '@carvertical/ui';
import type { PropsWithChildren, ReactNode } from 'react';
import { Section } from 'components/landings/common/Section';
import Page from './Page';
import { FormattedText } from './FormattedText';

type TextPageProps = {
  title: string;
  centered?: boolean;
  breadcrumbsHidden?: boolean;
  contentTitleHidden?: boolean;
  contentBeforeChildren?: ReactNode;
};

const TextPage = ({
  breadcrumbsHidden = false,
  centered = false,
  children,
  contentBeforeChildren,
  contentTitleHidden = false,
  title,
}: PropsWithChildren<TextPageProps>) => (
  <Page {...(breadcrumbsHidden && { breadcrumbs: false })}>
    <Section variant="s">
      <Container>
        <Grid>
          <GridItem
            span={{ mobileUp: 12, tabletLandscapeUp: 8, desktopUp: 6 }}
            start={{ mobileUp: 1, tabletLandscapeUp: 3, desktopUp: 4 }}
          >
            <FormattedText centered={centered}>
              {!contentTitleHidden && (
                <Heading as="h1" variant="l">
                  {title}
                </Heading>
              )}
              {contentBeforeChildren}
              {children}
            </FormattedText>
          </GridItem>
        </Grid>
      </Container>
    </Section>
  </Page>
);

export type { TextPageProps };
export { TextPage };
