import PropTypes from 'prop-types';
import { useState } from 'react';
import { useInterval } from 'react-use';
import withTranslate from '../hoc/withTranslate';
import { TextPage } from './common/TextPage';
import Redirect from './common/Redirect';

const REDIRECT_AFTER_SECONDS = 3;
const COUNTDOWN_INTERVAL = 1000;

const NotFoundPage = ({ t }) => {
  const [redirectCountdown, setRedirectCountdown] = useState(REDIRECT_AFTER_SECONDS);

  const redirectDelay = redirectCountdown > 0 ? COUNTDOWN_INTERVAL : null;

  useInterval(() => {
    setRedirectCountdown((countdown) => Math.max(countdown - 1, 0));
  }, redirectDelay);

  return (
    <TextPage title={t('notFoundPage.title')} centered breadcrumbsHidden>
      <p>{t('notFoundPage.text')}</p>
      <p>
        {redirectCountdown <= 0 && <Redirect to="/" />}
        {t('general.redirectingInLabel', {
          timeLeft: redirectCountdown,
        })}
        ...
      </p>
    </TextPage>
  );
};

NotFoundPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate()(NotFoundPage);
